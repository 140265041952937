.ProjectPickerLogo {
  height: 8rem;
  width: 8rem;
  pointer-events: none;
  margin: 10px;
  padding: 10px;
}

.ProjectPicker {
  display: flex;
  justify-content: center;
}
